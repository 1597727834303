.MonthSubmitReminderBox {
    .SimpleCard {
        padding-top: 6px;
        padding-bottom: 5px;
        font-size: 0.9rem;
    }
    &.warning .SimpleCard {
        // background-color: #ffc798;
        background-color: #fef1de;
        color: #b93e00;
    }
    &.attention .SimpleCard {
        // background-color: #ff9898;
        background-color: #ffd8d8;
        color: #8b0303;

    }

    .WidgetCardBody {
        padding: 0;
    }
}