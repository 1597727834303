.TaskSelector2 {
  width: 100%;
  .dropdown-toggle {
    @media (max-width: 500px){
      max-width: unset !important;
    }
  }
  .dropdown,
  .dropdown-center {
    // max-width: 70%;
    .dropdown-toggle {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      height: 100%;
      background-color: transparent;

      // width: 100%;
      // text-overflow: ellipsis;
      // overflow: hidden;
      // padding-right: 30px;
      padding-right: 10px;
      min-height: calc(
        var(--bs-btn-padding-y) * 2 + var(--bs-btn-line-height) *
          var(--bs-btn-font-size) + 1px
      );
      max-height: calc(
        var(--bs-btn-padding-y) * 2 + 2 *
          (var(--bs-btn-line-height) * var(--bs-btn-font-size) + 1px)
      );

      // &::after {
      //   vertical-align: -webkit-baseline-middle;
      //   height: 100%;
      // }

      // .category {
      //   max-width: 10%;
      //   color: var(--text-secondary-on-surface);
      //   .categoryName {
      //     text-overflow: ellipsis;
      //     overflow: hidden;
      //   }
      // }
      // .taskName {
      //   text-overflow: ellipsis;
      //   overflow: hidden;
      // }

      // &:disabled {
      //   color: var(--text-disabled-on-surface);
      // }

      // @at-root .TaskSelector2-select#{&}{
      //   // form-select
      //   color: var(--bs-body-color);
      //   border: var(--bs-border-width) solid var(--bs-border-color);
      //   border-radius: var(--bs-border-radius);
      //   background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon,none);
      //   background-repeat: no-repeat;
      //   background-position: right 0.75rem center;
      //   background-size: 16px 12px;
      //   appearance: none;

      //   text-align: start;
      //   &::after {
      //     content: none;
      //   }
      // }
      // @at-root .TaskSelector2-dropdown#{&}{
      //   color: var(--primary-color);
      //   border-radius: unset;
      //   border: unset;
      //   border-bottom: 1px solid var(--secondary-color);
      //   border-bottom: 1px solid var(--secondary-color);
      // }
    }
    .dropdown-menu {
      max-width: 100vw;
      border: 1px solid var(--border-color);
      max-height: 300px;
      overflow-y: auto;
      overflow-x: auto;
      .dropdown-header {
        color: var(--text-secondary-on-surface);
        cursor: default;
        & .public {
          color: var(--text-disabled-on-surface);
        }
      }
      .dropdown-item {
        color: var(--text-primary-on-surface);
        &:hover {
          background-color: var(--hover-on-surface-light);
        }
        &:active {
          background-color: var(--hover-on-surface-dark);
        }
      }
    }
  }
  &.TaskSelector2-select .dropdown-toggle {
    display: inline-flex;
    align-items: center;
    justify-content:left;
    min-height: calc(
      var(--bs-btn-padding-y) * 2 + var(--bs-btn-line-height) *
        var(--bs-btn-font-size) + 1px
    );
    max-height: calc(
      var(--bs-btn-padding-y) * 2 + 2 *
        (var(--bs-btn-line-height) * var(--bs-btn-font-size) + 1px) + 2px
    );

    width: 100%;
    // form-select
    color: var(--bs-body-color);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    background-image: var(--bs-form-select-bg-img),
      var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    appearance: none;

    text-align: start;
    &::after {
      content: none;
    }
    &:focus{
      border-color: var(--secondary-color);
    }
  }
  &.TaskSelector2-dropdown .dropdown-toggle {
    color: var(--primary-color);
    border-radius: unset;
    border: unset;
    border-bottom: 1px solid var(--secondary-color);
    border-bottom: 1px solid var(--secondary-color);
  }
  .TaskSelectorPlaceholder {
    &.placeholder,
    &.placeholder-wave,
    &.placeholder-glow {
      background-color: transparent;
      .btn {
        background-color: transparent;
        color: var(--primary-color);
        border-radius: unset;
        border: unset;
        border-bottom: 1px solid var(--secondary-color);
        border-bottom: 1px solid var(--secondary-color);
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 70%;
      }
    }
  }
}
